@import 'assets/styles/scss/variables';

.container {
  margin: 17px 40px;
  background-color: $light;
  padding-right: 24px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
}

.draggableElement {
  margin-left: 24px;
}

.cascadeBody {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 16px;
  padding-bottom: 24px;
  margin-left: 24px;
}

.formBody {
  display: flex;
  gap: 20px;
}

.textarea {
  width: 600px;
}

.select {
  width: 700px;
}

.arrowIcon {
  margin-top: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.underline {
  cursor: pointer;
  text-decoration: underline dashed;
}

.removeButton {
  margin-right: 20px;
}

.topBarText {
  margin-right: 20px;
}

.cascadeNameInput {
  border: none;
  font-size: 20px;
  margin-left: 12px;
  font-weight: 700;
  outline: none;
  padding: 0;
  transition: 0.2s ease-out;
  min-width: 500px;

  &:hover {
    background-color: $third-gray;
  }
}

.numberInput {
  min-width: 170px;
}

.displayNone {
  display: none;
}

.error {
  border-color: $error-primary;

  &:focus {
    border-color: $error-primary !important;
    box-shadow: 0 0 0 3px rgba($error-primary, 0.2) !important;
  }

  &:hover {
    border-color: $error-primary !important;
  }
}
