@import 'assets/styles/scss/variables';

.container {
  background-color: $seventh-gray;
  min-height: 100vh;
  padding: 20px 0;
}

.statusTableHeader {
  display: block;
  text-align: right;
  margin-right: 40px;
}

.card {
  padding: 35px;
}

.tableRow {
  cursor: pointer;
}

.pagination {
  margin-top: 40px;
  margin-right: 40px;
}
