@import 'assets/styles/scss/variables';

.sendingOffersInfo {
  text-align: center;
  font-size: $x-large-font-size;
}
.navigationButton {
  width: fit-content;
  margin: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkIcon {
  position: absolute;
  top: -2px;
  right: -10px;
  font-size: 36px;
  background-color: $light;
  border: 4px solid $light;
  border-radius: 50%;
}

.iconsWrapper {
  position: relative;
  margin: 60px 0;
  color: $secondary-blue;
}
