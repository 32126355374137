.ant-radio-inner {
  width: 18px;
  height: 18px;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 9px;
    transform: translate(-50%, -50%) !important;
  }
}
