@import 'assets/styles/scss/variables';

.container {
  width: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3px;

  &:hover {
    .dot {
      background-color: $black;
    }
  }

  &:active {
    .dot {
      background-color: $black;
    }
  }
}

.dot {
  width: 6px;
  height: 6px;
  background-color: $sixth-gray;
  border-radius: 50%;
  transition: 0.4s;
}
