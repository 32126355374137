@import 'assets/styles/scss/variables';

.cascadeHeader {
  display: flex;
  justify-content: space-between;
}

.cascadeHeaderLeftPart {
  display: flex;
}

.sentAfterWrapper {
  display: flex;
  gap: 15px;
  color: $text-primary-color;
}

.sentAfter {
  font-size: 16px;
  font-weight: 400;
  color: $text-third-color;
}

.recipientsTable {
  padding-bottom: 15px;
}

.recipientsInfo {
  font-weight: 400;
  color: $text-third-color;
  margin-left: 15px;
}

.fieldExplanation {
  font-size: 16px;
}

.noResults {
  text-align: center;
  font-size: $x-large-font-size;
}

.errored {
  border-color: $error-primary;

  &:focus {
    border-color: $error-primary !important;
    box-shadow: 0 0 0 3px rgba($error-primary, 0.2) !important;
  }

  &:hover {
    border-color: $error-primary !important;
  }
}
