@import 'assets/styles/scss/variables';

.container {
  display: flex;
  gap: 20px;
}

.formElementWrapper {
  display: block;
}

.textarea {
  width: 700px;
}

.error {
  border-color: $error-primary;

  &:focus {
    border-color: $error-primary !important;
    box-shadow: 0 0 0 3px rgba($error-primary, 0.2) !important;
  }

  &:hover {
    border-color: $error-primary !important;
  }
}
