@import 'assets/styles/scss/variables';

.warningIconWrapper {
  position: relative;
}

.warningIcon {
  position: absolute;
  bottom: -2px;
  left: 6px;
  color: $primary-yellow !important;
  font-size: 13px;
}
