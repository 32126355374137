@import 'assets/styles/scss/variables';

.container {
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select {
  width: 230px;
  box-shadow: none !important;
}

.selectArrowIcon {
  color: $secondary-gray;
}

.tag {
  margin-right: 3px;
}

.dropdown {
  min-width: 230px !important;
}

.optionBodyWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  column-gap: 10px;
}

.jobNumberText {
  margin-left: 8px;
}

.searchInput {
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none !important;
}
