@import '../../assets/styles/scss/variables';

.container {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 40px 55px;
  background-color: $light;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
}

.title {
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
  margin-right: 15px;
  width: 100%;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.actions {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
