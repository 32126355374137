.container {
  display: block;
}

.select {
  width: 200px;
  margin-right: -8px;
}

.lastSelect {
  width: 200px;
}

.input {
  width: 200px;
  padding: 5px 10px;
}

.addFilterSelect {
  width: 600px;
}
