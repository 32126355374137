@import 'assets/styles/scss/variables';

.backToListButton {
  margin-right: 20px;
}

.assignedLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.selectVendorButton {
  margin-right: 10px;
}

.userIconWrapper {
  width: 45px;
  height: 45px;
  background-color: $seventh-gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assignedLabelText {
  display: block;
  font-size: 13px;
  color: $eighth-gray;
}

.assignedLabelUserName {
  font-size: $h3-font-size;
  font-weight: bold;
}
