.dot {
  width: 20px;
  height: 20px;
  bottom: 5px;
  left: -5px;
  position: relative;
  display: inline-block;
}

.steps {
  margin-top: 80px;
  width: 700px;
}
