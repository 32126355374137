@import 'assets/styles/scss/variables';

.errorWrapper {
  max-width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  margin-bottom: 60px;
  font-size: 130px;
  color: $eighth-gray;
}

.text {
  font-size: 19px;
  text-align: center;
  line-height: 120%;
}

.errorCode {
  font-size: 19px;
  color: $eighth-gray;
}
