@import '../variables';

.ant-form-item-label > label {
  color: $eighth-gray;
  font-size: 13px !important;
  font-weight: 600;
}
.ant-form-item {
  color: $eighth-gray;
  margin-bottom: 0;
  font-size: 13px !important;
}
.ant-form-vertical .ant-form-item:not(:last-of-type) {
  margin-bottom: 25px!important;
}
.ant-select-dropdown {
  padding: 0;
  border-radius: 0;
}

.ant-select-selector {
  border-radius: 0!important;
  font-size: 13px !important;
}

.ant-select-item-option {
  font-size: 13px !important;
  color: $eighth-gray!important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5!important;
  color: $eighth-gray!important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #969595!important;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12)!important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #969595!important;
}

.ant-form-item-explain, .ant-form-item-extra {
  font-size: 13px;
  color: $eighth-gray;
  padding-top: 8px;
}

.ant-radio-wrapper {
  font-size: 13px!important;
}

.ant-input {
  border-radius: 0;
  font-size: 13px;

  &:hover {
    border-color: #969595!important;
  }

  &:focus {
    border-color: #969595!important;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12)!important;
  }
}

.ant-checkbox-wrapper {
  font-size: 13px!important;
  line-height: 30px;
}

.ant-checkbox-inner {
  border-radius: 0;
}

.ant-picker {
  border-radius: 0!important;
}
.ant-picker-input > input {
  font-size: 13px!important;
}
.ant-picker-focused {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12)!important;
}
.ant-picker:hover, .ant-picker-focused {
  border-color: #969595!important;
}

.ant-picker-panel {
  font-size: 13px!important;
}
