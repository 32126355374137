@import '../variables';

.ant-btn {
  font-size: $normal-font-size;
  min-width: 100px;
  height: 38px !important;

  &:hover {
    border-color: rgba(0, 0, 0, 0.8) !important;
    color: rgba(0, 0, 0, 0.8) !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
  }

  &:active {
    border-color: inherit !important;
    color: inherit !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
  }

  &:focus {
    border-color: rgba(0, 0, 0, 0.4) !important;
    color: inherit !important;
    background-color: rgba(0, 0, 0, 0.02) !important;
  }
}

.ant-btn-primary {
  &:hover {
    border: 1px solid transparent !important;
    color: white !important;
    background-color: $third-blue !important;
  }

  &:focus {
    border: 1px solid transparent !important;
    color: white !important;
    background-color: $third-blue !important;
  }

  &:active {
    border: 1px solid transparent !important;
    color: white !important;
    background-color: $third-blue !important;
  }
}

.ant-btn-dangerous.ant-btn-primary {
  &:hover {
    background-color: $error-primary !important;
  }

  &:focus {
    background-color: $error-primary !important;
  }

  &:active {
    background-color: $error-primary !important;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}
