@import 'assets/styles/scss/variables';
.card {
  margin: 17px 40px;
}

.divider {
  border-top-color: rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 0;
  border-top-width: 1px;
}

.body {
  padding-top: 16px;
  border-top-width: 1px;
  color: $eighth-gray;
  font-size: $normal-font-size;
}

.firstElement {
  padding-top: 24px;
}
