@import 'assets/styles/scss/variables';

.assignVendorButton {
  width: 100%;
}

.messageWrapper {
  display: flex;
  justify-content: center;
}

.successIcon {
  font-size: 20px;
  margin-right: 6px;
  color: $primary-green !important;
}

.notSentIcon {
  font-size: 22px;
  margin-right: 6px;
}

.messageText {
  font-size: 14px;
  color: inherit;
  max-width: 160px;
  font-weight: 500;
}

.successMessage {
  color: $primary-green;
  font-weight: 600;
}

.tmsNoResponseMessageWrapper {
  display: flex;
  justify-content: center;
  color: $error-primary;
}
