@import 'button';
@import 'card';
@import 'table';
@import 'steps';
@import 'form';
@import 'modal';
@import 'message';
@import 'radio-button';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');

.ant-tooltip-inner {
  text-align: center;
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
}
