@import 'assets/styles/scss/variables';

.container {
  background-color: $seventh-gray;
  min-height: 100vh;
}

.details {
  margin-top: 17px;
}
