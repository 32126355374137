@import 'assets/styles/scss/variables';

.messageWrapper {
  :global {
    .ant-message-notice-content {
      box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.12);
    }
  }
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  min-width: 600px;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.contentSuccessWrapper {
  @extend .contentWrapper;
  background-color: $success-secondary;
  border: 1px solid $success-primary;
}

.contentErrorWrapper {
  @extend .contentWrapper;
  background-color: $error-secondary;
  border: 1px solid $error-primary;
}

.iconWrapper {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
}

.iconSuccessWrapper {
  background-color: #91a468;
  @extend .iconWrapper;
}

.iconErrorWrapper {
  background-color: $error-primary;
  @extend .iconWrapper;
}

.icon {
  color: $light !important;
  position: absolute !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  font-size: 20px !important;
}

.messageContent {
  font-weight: 600;
  margin: 0 25px;
  font-size: 13px;
}

.messageSuccessContent {
  color: $success-primary;
  @extend .messageContent;
}

.messageErrorContent {
  color: $error-primary;
  @extend .messageContent;
}

.displayNone {
  display: none;
}

%closeIcon {
  font-size: 14px !important;
  margin: 16px 25px !important;
}

.successCloseIcon {
  color: $success-primary !important;
  @extend %closeIcon;
}

.errorCloseIcon {
  color: $error-primary !important;
  @extend %closeIcon;
}
