@import 'assets/styles/scss/variables';

.sendingOffersInfo {
  text-align: center;
  font-size: $x-large-font-size;
}

.container {
  width: 270px;
  height: 270px;
  margin: 50px auto 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.animatedBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $secondary-blue;
  border-radius: 50%;
  animation: loader infinite 2s;
}

@keyframes loader {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.7;
  }

  30% {
    width: 0px;
    height: 0px;
    opacity: 0.7;
  }

  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
