@import '../../assets/styles/scss/variables';

.container {
  min-height: 88vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 30px;
  gap: 30px;
}

.column {
  background-color: $light;
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 20px;
}

.title {
  width: 100%;
  text-align: center;
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
}

.input {
  padding: 15px 20px;
  text-align: center;
  background-color: #eee;
}
