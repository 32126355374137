.container {
  display: block;
}

.select {
  width: 270px;
  margin-right: -8px;
}

.lastSelect {
  width: 270px;
}

.addSortingSelect {
  width: 600px;
}

.sortingText {
  display: block;
  width: 50px;
  text-align: right;
}
