@import '../../assets/styles/scss/variables';

.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.prospectingPlanDetails {
  width: 1000px;
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.formActionsBar {
  height: 80px;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.prevIcon {
  font-size: 18px;
  margin-right: 8px;
}
