@import '../../assets/styles/scss/variables';

.label {
  font-size: $h3-font-size;
}

.sent {
  color: $primary-green;
}

.icon {
  font-size: 22px;
}
