@import 'assets/styles/scss/variables';

.container {
  background-color: $seventh-gray;
  min-height: 100vh;
  padding-bottom: 50px;
}

.buttonsWrapper {
  padding: 0 40px;
  display: block;
}

.addCascadeButtonWrapper {
  display: flex;
  justify-content: center;
  border: 1px dashed $sixth-gray;
  padding: 17px;
}

.saveButton {
  margin-right: 10px;
  margin-left: 50px;
}

.backToListButton {
  min-width: 130px;
  margin-right: 20px;
}

.editableHeaderInput {
  border: none;
  font-size: 26px;
  font-weight: 600;
  width: 100%;
  outline: none;
  padding: 0;
  transition: 0.2s ease-out;

  &:hover {
    background-color: $third-gray;
  }
}

.editableHeaderInputError {
  &::placeholder {
    color: rgba($error-primary, 0.7);
  }
}
