@import '../variables';

.ant-card-head {
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
  color: $text-fourth-color;
  border-bottom: none;
}

.ant-card-body {
  padding: 0 24px 24px 24px;
}

.ant-card-loading-block {
  height: 16px;
  margin: 7px 7px;
}

.ant-card-loading-content {
  padding-top: 16px;
}

.ant-card-head-title {
  padding: 20px 0;
}
