.ant-modal-header {
  border-bottom: none;
}

.ant-modal-title {
  font-size: 20px;
}

.ant-modal-content {
  padding: 20px;
}

.ant-modal-footer {
  text-align: left;
  border-top: none;
}

.ant-modal-close-x {
  width: 76px;
  height: 76px;
  line-height: 76px;
}
