.cardWrapper {
  max-width: 820px;
  margin: 100px auto 60px;
  padding: 0 15px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  background-color: #fefdf8;
  border: 1px solid #e8ac4a;
}

.label {
  font-size: 13px;
  margin: 5px 0 25px;
}

.buttonsWrapper {
  display: flex;
  gap: 10px;
}
