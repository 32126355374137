@import 'assets/styles/scss/variables';

.container {
  border-width: 1px 1px 1px 6px;
  border-style: solid;
  line-height: 33px;
  vertical-align: middle;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  padding: 0 14px;
  text-transform: uppercase;
}

.yellow {
  color: $primary-yellow;
  border-color: $primary-yellow;
}

.red {
  color: $primary-red;
  border-color: $primary-red;
}

.green {
  color: $primary-green;
  border-color: $primary-green;
}

.blue {
  color: $primary-blue;
  border-color: $primary-blue;
}

.gray {
  color: $primary-gray;
  border-color: $primary-gray;
}
