//Main
$black: #000000;
$light: #ffffff;
$primary-gray: #cccccc;
$secondary-gray: #3b3b3b;
$third-gray: #f3f3f3;
$fourth-gray: #969696;
$fifth-gray: #e6e6e6;
$sixth-gray: #c2c2c2;
$seventh-gray: #eeeeee;
$eighth-gray: #5e5e5e;
$primary-yellow: #e0b04f;
$primary-green: #659c06;
$primary-red: #d54e3f;
$secondary-red: #bf4638;
$primary-blue: #5bbfde;
$secondary-blue: #0424d5;
$third-blue: #031ca3;

//Text
$text-primary-color: #3b3b3b;
$text-secondary-color: #222222;
$text-third-color: #898989;
$text-fourth-color: #303030;

//font-size
$small-font-size: 11px;
$normal-font-size: 13px;
$large-font-size: 13px;
$x-large-font-size: 16px;

// headers
$h1-font-size: 26px;
$h1-line-height: 30px;
$h1-font-weight: 600;

$h2-font-size: 20px;
$h2-line-height: 26px;
$h2-font-weight: 600;

$h3-font-size: 16px;
$h3-line-height: 20px;
$h3-font-weight: 600;
//spacing

// error
$error-primary: #ba3325;
$error-secondary: #fef9f7;

// info

// success
$success-primary: #4a6209;
$success-secondary: #f4f8f0;

//status
