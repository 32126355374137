.calendar {
  margin-bottom: 6px;
}

.text {
  display: block;
}

.timePopper {
  box-shadow: 0px 0px 20px 5px rgba(66, 68, 90, 0.2);
  margin-bottom: 10px;
  padding-bottom: 0 !important;

  :global {
    .react-datepicker {
      border-radius: 0;
      border-color: #e4e4e4;
      display: block;

      .react-datepicker__header {
        display: none !important;
      }

      li {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 35px !important;
        margin: 5px !important;
        border-radius: 7px;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;

        &:hover,
        &.react-datepicker__time-list-item--selected {
          font-weight: bold;
          color: rgb(54, 107, 160) !important;
          background-color: rgb(234, 245, 254) !important;
        }
      }
    }
  }
}

.datePopper {
  box-shadow: 0px 0px 20px 5px rgba(66, 68, 90, 0.2);
  margin-bottom: 10px;
  padding-bottom: 0 !important;

  :global {
    .react-datepicker {
      border-radius: 0;
      border-color: #e4e4e4;
      display: block;
      font-family: 'Open Sans', sans-serif;
    }

    .react-datepicker__header {
      border-bottom: 0;
      padding: 20px 0 5px;
      background: #fff;

      .DateTimePicker_datepickerHeader__3xIIu {
        padding: 5px 10px;
        font-family: 'Open Sans', sans-serif;
      }

      i {
        color: #216ba5 !important;
        font-size: 20px !important;
      }
    }

    .react-datepicker__day-names {
      background-color: #fff;
      text-transform: uppercase;
      font-size: 13px;

      .react-datepicker__day-name {
        color: #bebebe;
        margin: 6px 2px;
        font-weight: 600;
        width: 2.3rem;
        line-height: 2.3rem;
      }
    }

    .react-datepicker__month {
      background-color: #fff;
      margin: 0;
      padding: 10px 10px 15px;

      .react-datepicker__day {
        color: #3b3b3b;
        margin: 6px 2px;
        width: 2.3rem;
        line-height: 2.3rem;
        border-radius: 50%;
        font-size: 13px;
        font-weight: 500;

        &.react-datepicker__day--selected {
          border-radius: 50%;
          font-weight: bold;
          color: #fff;
        }

        &.react-datepicker__day--disabled {
          color: #bebebe;
        }

        &.react-datepicker__day--outside-month {
          color: #dbdbdb;
        }
      }
    }
  }
}

.input {
  width: 100%;
  opacity: 1 !important;
  height: 34px !important;
  color: #3b3b3b !important;
  border-radius: 0 !important;
  outline: none !important;
  border: 1px solid #cccccc !important;

  &:hover {
    border: 1px solid #969595 !important;
  }
  & > input {
    border: none;
    padding: 10px;
    max-width: 100%;
    height: 100%;
    flex: 1 0 auto;
    outline: none;
    text-align: left;
    background: #fff;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border-radius: 0;
  }
  &:focus-within {
    outline: 3px solid rgba(0, 0, 0, 0.12) !important;
  }
}

.errorInput {
  border-color: #ba3325 !important;

  &:hover {
    border-color: #ba3325 !important;
  }
}

.inputErrorMessage {
  color: #ba3325;
  font-size: 11px;
  margin-top: 2px;
}

.dateTimePickerWrapper {
  display: flex;
}

.datePicker {
  max-width: 130px;
  margin-right: 10px;
}

.timePicker {
  max-width: 95px;
}

.datePickerWrapper {
  width: auto;
}

.icon {
  font-size: 14px !important;
  border-left: 1px solid #cccccc !important;
  color: #8d8c8c;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  pointer-events: none;
}

.calendarIcon {
  right: 10px;
}

.datepickerHeader {
  margin-top: -7px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.arrowIcon {
  color: #216ba5;
  font-size: 20px;
  cursor: pointer;

  &[disabled] {
    opacity: 0.45;
    pointer-events: none;
  }
}

.select {
  border: 0;
  font-size: 14px;
  outline: none;
  text-align-last: right;
  background-color: inherit;
  font-weight: bold;
}

.timezone {
  color: #969696;
  margin: 5px 0;

  span {
    font-weight: 600;
    color: #898989;
  }
}

.expiryAdditionalInfo {
  margin-top: 10px;
}
