@import '../variables';

.ant-table {
  color: $eighth-gray;
  font-size: $normal-font-size !important;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #898989;
  }
}

.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 7px 10px !important;
}

.ant-table-tbody .ant-table-cell.clickable {
  cursor: pointer;
}

th.xtrfTableColumn {
  background-color: $light !important;
  font-weight: bold !important;
  color: $eighth-gray !important;
}

.ant-table-thead > tr > th {
  background-color: transparent !important;
  box-shadow: unset !important;
  border-bottom: 2px solid $primary-gray;
  font-weight: 500;
}
